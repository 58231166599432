import * as React from 'react';
import * as d3 from 'd3';
import * as groupBy from 'lodash.groupby';
import './dashboard.less';
import IHappinessRating from '../../../../common/models/IHappinessRating';
import {connect} from 'react-redux';
import {RootState} from '../../../store/store';

const t = d3.transition().duration(750);
const pinkColors = ['#FFFFFF', '#DD83B6', '#C44A8F', '#AF3A7C', '#9A2B6A', '#7C1D53'];
const greenColors = ['#FFFFFF', '#8EE47C', '#58B844', '#469635', '#337226', '#224E19'];

export interface IDashboardProps {
	stats: Array<IHappinessRating>;
}

class DashboardComponent extends React.Component<IDashboardProps> {
    private containerRef: HTMLDivElement;
    private svg: any;

    constructor(props: IDashboardProps) {
        super(props);
    }

    public componentDidMount(): void {
		const dom = this.containerRef;
		const that = this;
		const width = that.containerRef ? that.containerRef.offsetWidth : 0;
		const height = that.containerRef ? that.containerRef.offsetHeight : 0;
		this.svg = d3.select(dom)
			.append('svg')
			.attr('width', width)
			.attr('height', height);
		this.drawChart();
    }

	public componentDidUpdate() {
		// this.drawChart();
	}

    public render(): JSX.Element {
        return <div className='dashboard-container' ref={r => this.containerRef = r!}></div>;
	}

	private drawChart(): void {
		const dom = this.containerRef;
		const that = this;

		const groupedData = groupBy(this.props.stats, d => {
		    return `${d.score}-${d.username}`;
		});
		const mappedData = Object.keys(groupedData).map(key => {
		    const keyParts = key.split('-');
		    return {
                ...groupedData[key],
                username: keyParts[1],
                score: keyParts[0],
                count: Math.floor(groupedData[key].length / 2)
            };
        });

		const rScale = d3.scaleLinear()
			.domain([0, d3.max(mappedData, d => d.count)])
			.range([30, 150]);

		const circles = this.svg.selectAll('circle').data(mappedData);
		const labels = this.svg.selectAll('text').data(mappedData);

		circles.exit().remove();

		circles.transition(t).attr('r', d => rScale(d.count));
		labels.transition(t).text(d => d.score + ': ' + d.count);

		const node = circles
			.enter()
			.append('g')
			.call(d3.drag()
				.on('start', dragstarted)
				.on('drag', dragged)
				.on('end', dragended))
			.on('mouseover', d => {
				tooltip.html(d.score + ': ' + d.count);
				return tooltip.style('visibility', 'visible');
			})
			.on('mousemove', function() {
				return tooltip.style('top', (d3.event.pageY - 10) + 'px').style('left', (d3.event.pageX + 10) + 'px');
			})
			.on('mouseout', () => tooltip.style('visibility', 'hidden'));

		function ticked() {
			const width = that.containerRef ? that.containerRef.offsetWidth : 0;
			const height = that.containerRef ? that.containerRef.offsetHeight : 0;
			node.attr('transform', d => {
				return 'translate(' + [d.x + (width / 2), d.y + (height / 2)] + ')';
			});
		}

		const simulation = d3.forceSimulation(mappedData)
			.force('charge', d3.forceManyBody().strength())
			.force('x', d3.forceX())
			.force('y', d3.forceY())
			.force('collide', d3.forceCollide().strength(0.1).radius(d => rScale(d.count) + 2).iterations(16))
			.on('tick', ticked);

		function dragstarted(d) {
			if (!d3.event.active) simulation.alphaTarget(0.3).restart();
			d.fx = d.x;
			d.fy = d.y;
		}

		function dragged(d) {
			d.fx = d3.event.x;
			d.fy = d3.event.y;
		}

		function dragended(d) {
			if (!d3.event.active) simulation.alphaTarget(0);
			d.fx = null;
			d.fy = null;
		}

		node.append('circle')
			.attr('r', d => rScale(d.count))
			.style('fill', d => {
			    return d.username === 'pedro' ? greenColors[d.score] : pinkColors[d.score];
            });

		node.append('text')
			.attr('text-anchor', 'middle')
			.attr('x', '2px')
			.attr('y', '4px')
			.text(d => d.score + ': ' + d.count);

		const tooltip = d3.select(dom)
			.append('div')
			.style('position', 'absolute')
			.style('visibility', 'hidden')
			.style('color', 'white')
			.style('padding', '8px')
			.style('background-color', '#626D71')
			.style('border-radius', '6px')
			.style('text-align', 'center')
			.style('font-family', 'monospace')
			.style('width', '40px')
			.text('');
    }
}

const mapStateToProps = (state: RootState) => ({
	stats: state.calendar.entries,
});

export const Dashboard = connect(mapStateToProps)(DashboardComponent);
