import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLogin, setPassword, setUsername } from '../../store/login';
import { RootState } from '../../store/store';
import './login.less';

export function Login() {
	const username = useSelector((state: RootState) => state.login.username);
	const password = useSelector((state: RootState) => state.login.password);
	const hasError = useSelector((state: RootState) => state.login.hasError);

	const dispatch = useDispatch();
	const handleLogin = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		dispatch(requestLogin());
	};
	const handleUsernameChange = (ev: any) => dispatch(setUsername(ev.target.value));
	const handlePasswordChange = (ev: any) => dispatch(setPassword(ev.target.value));

	const [ selectedTab, setSelectedTab ] = React.useState<string>('login');

	return (
		<div className='login-wrapper'>
			<div className='login-container'>
				<ul className='login-nav'>
					<li className={selectedTab === 'login' ? 'login-nav__item active' : 'login-nav__item'}>
						<a onClick={() => setSelectedTab('login')}>Sign In</a>
					</li>
					<li className={selectedTab === 'register' ? 'login-nav__item active' : 'login-nav__item'}>
						<a onClick={() => setSelectedTab('register')}>Sign Up</a>
					</li>
				</ul>

				{
					selectedTab === 'login' ? (
							<form onSubmit={handleLogin} method={'post'}>
								<label htmlFor='login-input-user' className='login__label'>Username</label>
								<input id='login-input-user' name='username' className='login__input' type='text' required value={username} onChange={handleUsernameChange}/>

								<label htmlFor='login-input-password' className='login__label'>Password</label>
								<input id='login-input-password' name='password' className='login__input' type='password' required value={password} onChange={handlePasswordChange}/>

								<button type='submit' className='login__submit'>Sign in</button>
								{hasError ? <div>Invalid username or password</div> : null}
							</form>
						) :
						<span className='warning-text'>Registration is currently disabled.</span>
				}
				<a href='#' className='login__forgot'>Forgot Password?</a>
			</div>
		</div>
	);
}
