import * as React from 'react';
import './timeline.less';
import ITimelineEntry from '../../../common/models/ITimelineEntry';
import * as Modal from 'react-modal';
import {TimelineEntry} from './timeline-new-entry';
import { Timeline as KLTimeline } from '@knight-lab/timelinejs';
import '@knight-lab/timelinejs/dist/css/timeline.css';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {END_STREAM, START_STREAM} from '../../store/metadata';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import {setIsEditing} from '../../store/timeline';
import {useDataStream} from '../hooks/useDataStream';
import Bootstrapper from '../../bootstrapper';
import classnames from 'classnames';

interface ITimelineData {
	events: Array<ITimelineEvent>;
}

interface ITimelineEvent {
	start_date: { year: number, month: number, day: number };
	text: { headline: string, text: string };
	media: { url?: string, caption: string };
}

export function Timeline() {
	const loggedInUser = useSelector((state: RootState) => state.login.username);
	const isConnected = useSelector((state: RootState) => state.metadata.isWsAlive);
	const isEditing = useSelector((state: RootState) => state.timeline.isEditing);
	const isBusy = useSelector((state: RootState) => state.timeline.isBusy);
	const data = useSelector((state: RootState) => state.timeline.entries);
	const dispatch = useDispatch();
	const handleEditStart = () => dispatch(setIsEditing(true));
	const handleEditEnd = () => dispatch(setIsEditing(false));

	useDataStream(MessagingTopics.RegisterTimelineStream);

	React.useEffect(() => {
		redrawTimeline();
		handleEditEnd();
	}, [data.length, isBusy]);

	const redrawTimeline = () =>  {
		if (!data.length) {
			return;
		}
		const events = mapData(data);
		const options = {
			hash_bookmark: true,
			start_at_end: true,
			scale_factor: 1,
			font: 'ubuntu'
		};

		setTimeout(() => {
			const tl = new KLTimeline('timeline-embed', events, options);
			requestAnimationFrame(() => tl.goToEnd()); // start_at_end property doesn't work hence doing it manually
		}, 310); //after the transitions is done, so the window height is correct...
	};

	return (
		<div className={`timeline ${loggedInUser}`}>
			<div className='timeline__toolbar'>
				<div className='timeline__toolbar timeline__toolbar--right'>
					<button type='button'
							className={
								classnames(
									{
										'btn': true,
										'btn-new-entry': isConnected,
										'btn-new-entry-disabled': !isConnected
									})}
							disabled={!isConnected}
							onClick={() => {
								handleEditStart();
								Bootstrapper.bootstrapNotification();
							}}>
						<i className='material-icons'>add_circle</i>
					</button>
				</div>
			</div>
			<div id='timeline-embed'></div>
			<Modal
				className='timeline-entry-modal'
				overlayClassName='timeline-entry-modal-overlay'
				isOpen={isEditing}
				closeTimeoutMS={300}
				ariaHideApp={false}
				onRequestClose={handleEditEnd}
			>
				<TimelineEntry />
			</Modal>
		</div>
	);
}

const mapData = (data: Array<ITimelineEntry> = []): ITimelineData => {
	const events: Array<ITimelineEvent> = data.map((d: ITimelineEntry) => {
		return {
			start_date: {
				year: d.year,
				month: d.month,
				day: d.day
			},
			text: {
				headline: d.title,
				text: d.text
			},
			media: {
				url: d.mediaUrl !== 'n/a' ? d.mediaUrl : '',
				caption: ''
			},
			unique_id: `${d.year}-${d.month}-${d.day}--${d.username}`
		};
	});
	return {
		events
	};
};
