import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import { DateFormat } from '../../common/utils/dateUtils';
import MessagingTopics from '../../common/messaging/messagingTopics';

const initialDay = DateTime.fromFormat('2017-07-30', DateFormat.SHORT_ISO);
const today = DateTime.now();

const totalDaysDiff = today.diff(initialDay, ['days']);
const diff = today.diff(initialDay, ['months', 'days', 'years']);

export interface MetadataState {
    totalDaysTogether: number;
    daysTogether: number;
    yearsTogether: number;
    monthsTogether: number;
    isWsAlive: boolean;
}

const initialState: MetadataState = {
    totalDaysTogether: Math.floor(totalDaysDiff.days),
    daysTogether: Math.floor(diff.days),
    yearsTogether: diff.years,
    monthsTogether: diff.months,
    isWsAlive: false
};

export const BOOTSTRAP = createAction('BOOTSTRAP');
export const START_STREAM = createAction<MessagingTopics>('START_STREAM');
export const END_STREAM = createAction<MessagingTopics>('END_STREAM');

export const metadataSlice = createSlice({
    name: 'metadata',
    initialState,
    reducers: {
        setIsWsAlive: (state, action: PayloadAction<boolean>) => {
            state.isWsAlive = action.payload;
        },
    },
});

export const { setIsWsAlive } = metadataSlice.actions;

export const metadataReducer = metadataSlice.reducer;
