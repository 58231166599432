export default class MessagingTopics {
	public static RegisterTimelineStream = 'RegisterTimelineStream';
	public static AddTimelineEntry = 'AddTimelineEntry';

	public static RegisterHappinessStream = 'RegisterHappinessStream';
	public static AddHappinessRating = 'AddHappinessRating';
	public static DeleteHappinessRating = 'DeleteHappinessRating';

	public static RegisterGoalsStream = 'RegisterGoalsStream';
	public static SaveGoals = 'SaveGoals';

	public static RegisterQuotesStream = 'RegisterQuotesStream';

	public static UnregisterStream = 'UnregisterStream';
	public static StreamCompleted = 'StreamCompleted';
}
