import * as React from 'react';
import classnames from 'classnames';

export interface ITextInputProps {
    placeholder?: string;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    autoFocus?: boolean;
    className?: string;
}

export default class TextInput extends React.PureComponent<ITextInputProps> {
    private ref: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount(): void {
        this.handleUpdate();
    }

    componentDidUpdate(): void {
        this.handleUpdate();
    }

    private handleUpdate = () => {
        if (this.ref && !this.props.value) {
            this.ref.current.value = '';
        }
        if (this.ref && this.props.autoFocus) {
            this.ref.current.focus();
        }
    };

    private handleChange = ev => {
        this.props.onChange(ev.target.value);
    };

    public render(): JSX.Element {
        return (
            <input
                ref={this.ref}
                autoFocus={this.props.autoFocus}
                className={classnames(this.props.className)}
                type='text'
                placeholder={this.props.placeholder}
                defaultValue={this.props.value}
                onChange={this.handleChange}
                onBlur={this.props.onBlur}
            />
        );
    }
}