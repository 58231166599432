import * as React from 'react';
import './app.less';
import {Homepage} from './homepage/homepage';
import {Timeline} from './timeline/timeline';
import {
	Route,
} from 'react-router-dom';
import {Header} from './header/header';
import {Calendar} from './happiness/calendar';
import {Goals} from './goals/goals';
import { withRouter } from 'react-router-dom';
import { Login } from './login/login';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import {CssBaseline} from '@mui/material';

export function App() {
	const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
	const isWsAlive = useSelector((state: RootState) => state.metadata.isWsAlive);

	if (!isLoggedIn) {
		return <Login />;
	}

	let SocketError = null;
	if (!isWsAlive) {
		SocketError = (
			<div className='network-error-panel'>
				<div className='network-error-panel-content'>
					<i className='material-icons'>error</i>
					<span className='network-error-panel-label'>Network issues detected</span>
				</div>
			</div>
		);
	}

	return (
		<Route render={({location}) => (
			<div className='app'>
				<CssBaseline />
				<Header />
				{SocketError}
				<Route key={location.key} location={location} path='/' component={Routes} />
			</div>
		)}/>
	);
}

const Routes = ({ match }) => (
	<>
		<Route exact path={match.url} component={Homepage}/>
		<Route path={match.url + 'timeline'} component={Timeline}/>
		<Route path={match.url + 'happiness'} component={Calendar}/>
		<Route path={match.url + 'goals'} component={Goals}/>
	</>
);

export default withRouter(App);
