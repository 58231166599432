import isBrowserEnvironment from './isBrowserEnvironment';
import isProduction from './isProduction';
import Logger from './logging/logger';
import getConsoleLogger from './logging/consoleLogger';
import getCompositeLogger from './logging/compositeLogger';

const LoggerFactory = {
	createLogger: (fileName: string): Logger => {
		if (isBrowserEnvironment) {
			return getConsoleLogger(fileName);
		} else {
			if (isProduction) {
				return getCompositeLogger([getConsoleLogger(fileName)]);
			} else {
				return getCompositeLogger([getConsoleLogger(fileName)]);
			}
		}
	}
};

export default LoggerFactory;