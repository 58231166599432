import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ITimelineEntry from '../../common/models/ITimelineEntry';

export interface TimelineState {
    entries: Array<ITimelineEntry>;
    isEditing: boolean;
    date?: string;
    title?: string;
    text?: string;
    mediaUrl?: string;
    isBusy: boolean;
}

const initialState: TimelineState = {
    entries: [],
    isEditing: false,
    date: '',
    isBusy: false,
};

export const timelineSlice = createSlice({
    name: 'timeline',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setIsEditing: (state, action: PayloadAction<boolean>) => {
            state.isEditing = action.payload;
        },
        setText: (state, action: PayloadAction<string>) => {
            state.text = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setMediaUrl: (state, action: PayloadAction<string>) => {
            state.mediaUrl = action.payload;
        },
        setDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
        },
        setTimelineEntries: (state, action: PayloadAction<Array<ITimelineEntry>>) => {
            state.entries = action.payload;
        },
        clear: (state, action: PayloadAction<boolean>) => {
            return initialState;
        },
        addTimelineEntryRequest: (state) => {
            state.isBusy = true;
        },
        addTimelineEntryResponse: (state) => {
            state.isBusy = false;
        },
    },
});

export const { setIsEditing, setText, setTitle, setMediaUrl, setDate, setTimelineEntries, clear,
    addTimelineEntryRequest, addTimelineEntryResponse } = timelineSlice.actions;

export const timelineReducer = timelineSlice.reducer;