import Logger from './logger';

function logInternal(fileName: string, logFunction: (..._) => void, args: Array<any>): void {
	if (args.length) {
		logFunction(new Date().toISOString(), fileName, ...args);
	}
}

export default function getConsoleLogger(fileName: string): Logger {
	return {
		info: (...args): void => {
			logInternal(fileName, console.info, args);
		},
		warn: (...args): void => {
			logInternal(fileName, console.warn, args);
		},
		error: (...args): void => {
			logInternal(fileName, console.error, args);
		}
	};
}