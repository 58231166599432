import * as React from 'react';
import './timeline-new-entry.less';
import DatePicker from '../components/date-picker/date-picker';
import TextInput from '../components/inputs/textInput';
import TextAreaInput from '../components/inputs/textAreaInput';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {
    addTimelineEntryRequest,
    clear,
    setDate,
    setIsEditing,
    setMediaUrl,
    setText,
    setTitle
} from '../../store/timeline';

export function TimelineEntry() {
    const isConnected = useSelector((state: RootState) => state.metadata.isWsAlive);
    const title = useSelector((state: RootState) => state.timeline.title);
    const text = useSelector((state: RootState) => state.timeline.text);
    const mediaUrl = useSelector((state: RootState) => state.timeline.mediaUrl);
    const date = useSelector((state: RootState) => state.timeline.date);
    const dispatch = useDispatch();
    const handleSetTitle = (value: string) => dispatch(setTitle(value));
    const handleSetText = (value: string) => dispatch(setText(value));
    const handleSetMediaUrl = (value: string) => dispatch(setMediaUrl(value));
    const handleSetDate = (_: any, value: string) => dispatch(setDate(value));
    const handleClear = () => dispatch(clear());
    const handleSave = () => dispatch(addTimelineEntryRequest());

    return (
        <div className='timeline-entry-container'>
            <div className='timeline-entry__header'>
                <div className='timeline-entry__title'>
                    <div>Title</div>
                    <TextInput value={title}  onChange={handleSetTitle}/>
                </div>
                <div className='timeline-entry__date'>
                    <div>Date</div>
                    <DatePicker onChange={handleSetDate} value={date}/>
                </div>
            </div>

            <div className='timeline-entry__content'>
                <div>Content</div>
                <TextAreaInput value={text} onChange={handleSetText}/>
            </div>
            <div className='timeline-entry__footer'>
                <div>📸 URL (optional)</div>
                <TextInput value={mediaUrl}  onChange={handleSetMediaUrl}/>
            </div>
            <div className='timeline-entry__buttons'>
                <button type='button' className='btn btn-clear'
                        disabled={!isConnected}
                        onClick={handleClear}>Clear</button>
                <button type='button'
                        className='btn btn-save'
                        disabled={!isConnected}
                        onClick={handleSave}>Save</button>
            </div>
        </div>
    );
}
