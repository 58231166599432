import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import {EpicType, RootState} from '../store';
import {ofType} from 'redux-observable';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import Envelope from '../../../common/messaging/envelope';
import {PayloadAction} from '@reduxjs/toolkit';
import {saveGoalsRequest, saveGoalsResponse} from '../goals';
import {ISaveGoalRequest} from '../../../common/models';

export const saveGoalsEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    ofType(saveGoalsRequest),
    map((action: PayloadAction<string>) => {
        const request: ISaveGoalRequest = {
            goals: {
                added: Object.values(state$.value.goals.pendingChanges.added),
                removed: Object.keys(state$.value.goals.pendingChanges.removed),
                updated: Object.values(state$.value.goals.pendingChanges.updated),
            },
            ordering: state$.value.goals.ordering.find(o => o.username === action.payload)
        };
        return request;
    }),
    switchMap((request: ISaveGoalRequest) => dependencies.messagingHub.getStream(MessagingTopics.SaveGoals, request).pipe(
        map((response: Envelope<any>) => {
            return saveGoalsResponse(response.payload.isSuccessfull);
        })
    )),
);
