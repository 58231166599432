import * as React from 'react';

export interface INumericInputProps {
    placeholder?: string;
    value?: number;
    onChange: (value: number) => void;
    className?: string;
    min?: number;
    max?: number;
    step?: number;
}

export default class NumericInput extends React.PureComponent<INumericInputProps> {
    private ref: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidUpdate(): void {
        if (this.ref && !this.props.value) {
            this.ref.current.value = '';
        }
    }
    private handleChange = ev => {
        this.props.onChange(Number(ev.target.value));
    };

    public render(): JSX.Element {
        return (
            <input
                ref={this.ref}
                className={this.props.className}
                type='number'
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
                placeholder={this.props.placeholder}
                defaultValue={this.props.value}
                onChange={this.handleChange}
            />
        );
    }
}