import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import {EpicType, RootState} from '../store';
import {ofType} from 'redux-observable';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import Envelope from '../../../common/messaging/envelope';
import {
    deleteCalendarEntryRequest,
    deleteCalendarEntryResponse
} from '../calendar';
import {PayloadAction} from '@reduxjs/toolkit';

export const deleteCalendarEntryEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    ofType(deleteCalendarEntryRequest),
    map((action: PayloadAction<string>) => {
        return action.payload;
    }),
    switchMap((request: any) => dependencies.messagingHub.getStream(MessagingTopics.DeleteHappinessRating, request).pipe(
        map((response: Envelope<any>) => {
            return deleteCalendarEntryResponse();
        })
    )),
);
