import * as React from 'react';
import {
    AppBar, Box, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Divider, Drawer,
    Tooltip, Fade, Menu, MenuItem, ListItemIcon, ToggleButton, ToggleButtonGroup, CssBaseline
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {handleLogout} from '../../store/login';
import {RootState} from '../../store/store';
import MenuIcon from '@mui/icons-material/Menu';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import './header.less';
import {AccountCircleRounded, LogoutRounded} from '@mui/icons-material';
import {DARK_CONTRAST_TEXT, LIGHT_CONTRAST_TEXT, ThemeType, useThemeState} from '../../ThemeProvider';
import {Logo} from './logo';

export function Header() {
    const dispatch = useDispatch();
    const totalDaysTogether = useSelector((state: RootState) => state.metadata.totalDaysTogether);
    const daysTogether = useSelector((state: RootState) => state.metadata.daysTogether);
    const monthsTogether = useSelector((state: RootState) => state.metadata.monthsTogether);
    const yearsTogether = useSelector((state: RootState) => state.metadata.yearsTogether);

    const navLinks = [
        {
            path: '/timeline',
            name: 'Timeline'
        },
        {
            path: '/happiness',
            name: 'Felicidad'
        },
        {
            path: '/goals',
            name: 'Objetivos'
        }
    ];
    const drawerWidth = 240;

	const logout = () => {
        dispatch(handleLogout());
        handleClose();
    };
    const toggleTheme = (event: React.MouseEvent<HTMLElement>, value: ThemeType) => {
        if(value) {
            setTheme(value);
            localStorage.setItem('theme', value);
        }
    };
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const toggleDrawer = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window.document.body : undefined;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const {theme, setTheme} = useThemeState();

    const color = theme === 'light' ? LIGHT_CONTRAST_TEXT : DARK_CONTRAST_TEXT;

    const drawer = (
        <Box onClick={toggleDrawer} sx={{ textAlign: 'center' }}>
            <Logo color={color}/>
            <Divider />
            <List>
                {navLinks.map((link) => (
                    <ListItem key={link.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={
                                <NavLink key={link.name}
                                         to={link.path}
                                         className='linkNoStyles'
                                >
                                    {link.name}
                                </NavLink>
                            } />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const tooltipContent = (
        <div>
            <div>{yearsTogether} years</div>
            <div>{monthsTogether} months</div>
            <div>{daysTogether} days</div>
        </div>
    );

    return (
    <Box sx={{ display: 'flex' }}>
        <AppBar component='nav' position='sticky' elevation={0}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton
                    aria-label='open drawer'
                    edge='start'
                    onClick={toggleDrawer}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                    color='inherit'
                >
                    <MenuIcon />
                </IconButton>
                <Box><Logo color={color}/></Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {navLinks.map((link) => (
                        <Typography variant='body1'
                                    component={NavLink}
                                    key={link.name}
                                    to={link.path}
                                    className='linkNoStyles navLink'
                                    sx={{color,
                                        '&::before': {
                                            backgroundColor: color
                                        }}}
                        >
                            {link.name}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip TransitionComponent={Fade} title={tooltipContent} arrow>
                        <Typography variant='caption'
                                    component='div'
                                    sx={{ display: 'flex' }}
                        >
                            <FavoriteIcon fontSize='small'/>&nbsp;{totalDaysTogether}
                        </Typography>
                    </Tooltip>
                    <IconButton
                        size='large'
                        aria-label='settings'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={handleMenu}
                        color='inherit'
                    >
                        <AccountCircleRounded />
                    </IconButton>
                    <Menu
                        id='menu-appbar'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <ToggleButtonGroup value={theme}
                                           exclusive
                                           onChange={toggleTheme}
                                           aria-label='outlined button group'
                                           sx={{ margin: '10px' }}
                        >
                            <ToggleButton value='light'><LightModeIcon /> Light</ToggleButton>
                            <ToggleButton value='dark'><DarkModeIcon/> Dark</ToggleButton>
                        </ToggleButtonGroup>
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <LogoutRounded fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
        <Box component='nav'>
            <Drawer
                container={container}
                variant='temporary'
                open={mobileOpen}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    </Box>
    );
}
