import MessagingTopics from '../../../common/messaging/messagingTopics';
import * as React from 'react';
import {END_STREAM, START_STREAM} from '../../store/metadata';
import {useDispatch} from 'react-redux';

export function useDataStream(topic: MessagingTopics) {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(START_STREAM(topic));
        return () => { dispatch(END_STREAM(topic)); };
    }, []);
}