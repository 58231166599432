import * as React from 'react';
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from 'flatpickr';

export interface IDatePickerProps {
    onChange: (dates: Array<Date>, dateString: string) => void;
    value?: string;
}

const DATE_FORMAT = 'Y-m-d';

export default class DatePicker extends React.Component<IDatePickerProps> {

    private ref: HTMLInputElement;
    private instance: flatpickr.Instance;

    constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        this.instance = flatpickr(this.ref, {
            altInput: true,
            altFormat: 'F j, Y',
            dateFormat: DATE_FORMAT,
            onChange: this.props.onChange,
        });
        this.updateDate();
    }

    public componentDidUpdate(): void {
        this.updateDate();
    }

    private updateDate(): void {
        if (!this.props.value && this.instance) {
            this.instance.clear();
        }
        if (this.props.value) {
            this.instance.setDate(this.props.value, false, DATE_FORMAT);
        }
    }

    public render(): JSX.Element {
        return (
            <input ref={r => this.ref = r!}></input>
        );
    }
}
