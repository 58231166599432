import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IGoalItem, IGoalOrdering, ILoadGoalResponse} from '../../common/models';
import Envelope from '../../common/messaging/envelope';

export interface GoalOrderingChange {
    sourceId: string;
    targetId?: string; // only applicable if moving inside a group
    newIndex?: number;
    nest?: boolean;
    username: string;
}

export interface GoalsState {
    isBusy: boolean;
    goals: IGoalItem[];
    ordering: IGoalOrdering[];
    pendingChanges: {
        added: { [key: string]: IGoalItem },
        updated: { [key: string]: IGoalItem },
        removed: { [key: string]: any },
        hasOrderingChange: boolean
    };
    editId?: string;
}

export interface GoalEvent {
    id: string;
    username: string;
    dateAdded?: string;
    dateCompleted?: string;
    goal?: string;
}

const initialState: GoalsState = {
    goals: [],
    ordering: [],
    isBusy: false,
    pendingChanges: { added: {}, updated: {}, removed: {}, hasOrderingChange: false },
};

const getUserOrderingIndex = (state: GoalsState, username) => state.ordering.findIndex(o => o.username === username);

export const goalsSlice = createSlice({
    name: 'goals',
    initialState,
    reducers: {
        setGoalEntries: (state: GoalsState, action: PayloadAction<ILoadGoalResponse>) => {
            state.goals = action.payload.goals;
            state.ordering = action.payload.ordering;
        },
        saveGoalsRequest: (state: GoalsState, action: PayloadAction<string>) => {
            state.isBusy = true;
        },
        saveGoalsResponse: (state: GoalsState, action: PayloadAction<boolean>) => {
            state.isBusy = false;
            if (action.payload) {
                state.pendingChanges = initialState.pendingChanges;
            }
        },
        addGoal: (state: GoalsState, action: PayloadAction<GoalEvent>) => {
            const newGoal = {
                id: action.payload.id,
                goal: 'New Goal',
                username: action.payload.username,
                dateAdded: action.payload.dateAdded,
                dateCompleted: undefined,
            };
            const userOrderingIndex = getUserOrderingIndex(state, action.payload.username);
            state.goals.push(newGoal);
            state.pendingChanges.added = { ...state.pendingChanges.added, [newGoal.id]: newGoal };
            state.ordering[userOrderingIndex].order = [
                ...state.ordering[userOrderingIndex].order,
                { id: newGoal.id, position: state.ordering[userOrderingIndex].order.length }
            ];
        },
        editGoal: (state: GoalsState, action: PayloadAction<GoalEvent>) => {
            state.editId = action.payload.id;
        },
        updateGoal: (state: GoalsState, action: PayloadAction<GoalEvent>) => {
            const goalBeingEditedIndex = state.goals.findIndex(g => g.id === action.payload.id);
            const itemBeingUpdatedIsPendingAdded = !!state.pendingChanges.added[action.payload.id];
            const updated = { ...state.goals[goalBeingEditedIndex], goal: action.payload.goal, dateCompleted: action.payload.dateCompleted };
            state.goals[goalBeingEditedIndex] = updated;
            if (itemBeingUpdatedIsPendingAdded) {
                state.pendingChanges.added[action.payload.id] = updated;
            } else {
                state.pendingChanges.updated[action.payload.id] = updated;
            }
        },
        deleteGoal: (state: GoalsState, action: PayloadAction<GoalEvent>) => {
            const deleteId = action.payload.id;
            const itemBeingRemovedIsPendingAdded = !!state.pendingChanges.added.deleteId;
            const itemBeingRemovedIsPendingUpdate = !!state.pendingChanges.added.deleteId;
            const userOrderingIndex = getUserOrderingIndex(state, action.payload.username);

            const isItemChild = !state.ordering[userOrderingIndex].order.find(g => g.id === deleteId);
            // reshuffle
            let newOrder = state.ordering[userOrderingIndex].order.filter(o => o.id !== deleteId);
            let groups = state.ordering[userOrderingIndex].groups;

            if (!isItemChild) {
                // need to reshuffle index if deleted wasn't a child
                const sourcePosition = state.ordering[userOrderingIndex].order.find(o => o.id === deleteId).position;
                newOrder = newOrder.map(o => o.position >= sourcePosition ? { id: o.id, position: o.position - 1 } : o);
                groups = groups.filter(g => g.id !== deleteId);
            } else {
                groups = groups.map(g => ({
                    ...g,
                    children: g.children.filter(c => c !== deleteId)
                }));
            }

            state.goals = state.goals.filter(g => g.id !== deleteId);
            if (itemBeingRemovedIsPendingAdded) {
                delete state.pendingChanges.added.deleteId;
            }
            if (itemBeingRemovedIsPendingUpdate) {
                delete state.pendingChanges.updated.deleteId;
            }
            if (!itemBeingRemovedIsPendingAdded && !itemBeingRemovedIsPendingUpdate) {
                state.pendingChanges.removed[deleteId] = deleteId;
            }

            state.ordering[userOrderingIndex].order = newOrder;
            state.ordering[userOrderingIndex].groups = groups;
        },
        updateGoalOrdering: (state: GoalsState, action: PayloadAction<GoalOrderingChange>) => {
            const newOrderingIndex = state.ordering.findIndex(o => o.username === action.payload.username);
            const newOrdering = { ...state.ordering[newOrderingIndex] };

            const { nest, newIndex, sourceId, targetId} = action.payload;

            if (nest) {
                // target must be a top level singleton
                const sourceIsChild = !newOrdering.order.find(o => o.id === sourceId);
                if (sourceIsChild) {
                    // 1. source is child from another group
                    newOrdering.groups = removeIdFromGroup(newOrdering.groups, sourceId);
                } else {
                    // 2. source is a top level singleton
                    const sourcePosition = newOrdering.order.find(o => o.id === sourceId).position;
                    newOrdering.order = newOrdering.order.filter(o => o.id !== sourceId);

                    // reshuffle
                    newOrdering.order = newOrdering.order.map(o => o.position >= sourcePosition ? { id: o.id, position: o.position - 1 } : o);
                }

                newOrdering.groups.push({
                    id: targetId,
                    children: [ sourceId ],
                });

            } else if (targetId) {
                // moving existing item inside a group
                const sourceIsChild = !newOrdering.order.find(o => o.id === sourceId);
                const targetIsChild = !newOrdering.order.find(o => o.id === targetId);
                if (targetIsChild && !sourceIsChild) {
                    return; // don't want 3 level nesting
                }
                if (sourceIsChild) {
                    // 1. source can be a group member (same group or different)
                    newOrdering.groups = removeIdFromGroup(newOrdering.groups, sourceId);
                } else {
                    // 2. source can be a top level
                    const sourcePosition = newOrdering.order.find(o => o.id === sourceId).position;
                    newOrdering.order = newOrdering.order.filter(o => o.id !== sourceId);
                    // reshuffle
                    newOrdering.order = newOrdering.order.map(o => o.position >= sourcePosition ? { id: o.id, position: o.position - 1 } : o);
                }
                newOrdering.groups.find(g => g.id === targetId).children.splice(newIndex, 0, sourceId);

            } else {
                // moving to top level
                const sourceIsChild = !newOrdering.order.find(o => o.id === sourceId);
                if (sourceIsChild) {
                    // 1. can move a current child item back to being a single top level goal
                    newOrdering.groups = removeIdFromGroup(newOrdering.groups, sourceId);
                } else {
                    // 2. moving a top level (single or group) to a different top level order

                    // first remove from current order
                    const sourcePosition = newOrdering.order.find(o => o.id === sourceId).position;
                    newOrdering.order = newOrdering.order.filter(o => o.id !== sourceId);
                    // reshuffle
                    newOrdering.order = newOrdering.order.map(o => o.position >= sourcePosition ? { id: o.id, position: o.position - 1 } : o);
                }
                // re-insert into desired position and reshuffle
                newOrdering.order = newOrdering.order.map(o => o.position >= newIndex ? { id: o.id, position: o.position + 1 } : o);
                newOrdering.order.push({ id: sourceId, position: newIndex });
            }

            state.ordering[newOrderingIndex] = newOrdering;
            state.pendingChanges.hasOrderingChange = true;
        }
    },
});

function removeIdFromGroup(groups: Array<{ id: string, children: Array<string>}>, id: string): Array<{ id: string, children: Array<string>}> {
    return groups.map(g => {
        const idBelongToGroup = g.children.find(s => s === id);
        if (idBelongToGroup) {
            const children = g.children.filter(s => s !== id);

            return children.length ? { id: g.id, children } : undefined;
        } else {
            return g;
        }
    }).filter(x => !!x); // remove undefined
}

export const { setGoalEntries, updateGoalOrdering, addGoal, deleteGoal, editGoal, updateGoal, saveGoalsRequest, saveGoalsResponse } = goalsSlice.actions;

export const goalsReducer = goalsSlice.reducer;