import * as React from 'react';
import {Calendar, CalendarControls} from 'react-yearly-calendar';
import * as moment from 'moment';
import TextInput from '../../components/inputs/textInput';
import NumericInput from '../../components/inputs/numericInput';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {
    addCalendarEntryRequest, deleteCalendarEntryRequest,
    setDayComment,
    setDayScore,
    setDisplayedDate,
    setDisplayedYear
} from '../../../store/calendar';
import {DateFormat} from '../../../../common/utils/dateUtils';

export function HappinessCalendar(props: any) {
    const isConnected = useSelector((state: RootState) => state.metadata.isWsAlive);
    const loggedInUser = useSelector((state: RootState) => state.login.username);
    const username = props.username;
    const belongsToUser = props.username === loggedInUser;
    const displayedDate = useSelector((state: RootState) => state.calendar.displayedDate || moment.utc().format(DateFormat.LEGACY_MOMENT_SHORT_ISO));
    const displayedYear = useSelector((state: RootState) => state.calendar.displayedYear || moment.utc().year());
    const dayScore = useSelector((state: RootState) => state.calendar.dayScore);
    const dayComment = useSelector((state: RootState) => state.calendar.dayComment);
    const isBusy = useSelector((state: RootState) => state.calendar.isBusy);
    const userData = useSelector((state: RootState) => state.calendar.entries.filter(e => e.username === username));
    const ratingInfo = userData.find(d => d.date.includes(displayedDate));

    const dispatch = useDispatch();
    const handleDisplayedDateChange = (moment: moment.Moment) => dispatch(setDisplayedDate(moment.format(DateFormat.LEGACY_MOMENT_SHORT_ISO)));
    const handleDisplayedYearChange = (year: number) => dispatch(setDisplayedYear(year));
    const handleDayCommentChange = (value: string) => dispatch(setDayComment(value));
    const handleDayScoreChange = (value: number) => dispatch(setDayScore(value));
    const handleDelete = () => dispatch(deleteCalendarEntryRequest(ratingInfo));
    const handleSave = () => dispatch(addCalendarEntryRequest());

    const handleDatePicked = (date: moment.Moment) => {
        const normalizedDate = normalizeDate(date);
        if (!isDateAfterToday(normalizedDate)) {
            handleDisplayedDateChange(normalizedDate);
            handleDisplayedYearChange(normalizedDate.year());
        }
    };

    const handlePrevYearClicked = () => handleDisplayedYearChange(displayedYear - 1);
    const handleNextYearClicked = () => handleDisplayedYearChange(displayedYear + 1);
    const handleTodayClicked = () => {
        const now = moment().utc().startOf('day');
        handleDisplayedDateChange(now);
        handleDisplayedYearChange(now.year());
    };

    const canEdit = belongsToUser && !isDateAfterToday(moment(displayedDate, DateFormat.LEGACY_MOMENT_SHORT_ISO)) && !ratingInfo;
    const canDelete = belongsToUser &&
        !isDateAfterToday(moment(displayedDate, DateFormat.LEGACY_MOMENT_SHORT_ISO)) &&
        ratingInfo?.dateEntered &&
        moment.duration(moment().utc().diff(moment(ratingInfo.dateEntered))).asHours() <= 1;

    const getDayClass = (date: moment.Moment) => {
        const normalizedDate = normalizeDate(date);

        if (isDateAfterToday(normalizedDate)) {
            return 'day day--disabled';
        }
        const dateString = normalizedDate.toJSON();
        const info = userData.find(s => dateString.includes(s.date));
        if (!info) {
            return 'day';
        }
        switch (info.score) {
            case 0:
                return 'day day--day0';
            case 1:
                return 'day day--day1';
            case 2:
                return 'day day--day2';
            case 3:
                return 'day day--day3';
            case 4:
                return 'day day--day4';
            case 5:
                return 'day day--day5';
        }
    };

    const getDayScoreElement = () => {
        if (canEdit) {
            return (
                <>
                    <NumericInput className='jlj-happiness__detail-input'
                                  min={0} max={5} step={1}
                                  value={dayScore}
                                  onChange={handleDayScoreChange}/>
                    <button type='button'
                            disabled={!isConnected}
                            onClick={handleSave}
                            className='btn btn-success btn-sm jlj-happiness__detail-button'>Save
                    </button>
                    <span className='jlj-happiness__detail-label'>
						{isBusy ? 'Saving...' : ''}
					</span>
                </>
            );
        } else {
            let deleteButton = canDelete ? (
                <button type='button'
                        disabled={!isConnected}
                        onClick={handleDelete}
                        className='btn btn-danger btn-sm jlj-happiness__detail-button'>Delete</button>
            ) : null;
            return (
                <span className='jlj-happiness__detail-value'>
					{ratingInfo && ratingInfo.score}
                    {deleteButton}
				</span>
            );
        }
    };

    const getDayCommentElement = () => {
        return canEdit ? (
            <TextInput onChange={handleDayCommentChange}
                       className='jlj-happiness__detail-input jlj-happiness__detail-input--long'
                       value={dayComment}/>
        ) : (
            <span className='jlj-happiness__detail-value'>
                {ratingInfo && ratingInfo.comment}
            </span>
        );
    };

    return (
        <div className='jlj-happiness-calendar'>
            <div className='jlj-happiness__details'>
                <div className='jlj-happiness__detail-group'>
						<span className='jlj-happiness__detail-value'>
							{displayedDate}
						</span>
                </div>
                <div className='jlj-happiness__detail-group'>
                    <span className='jlj-happiness__detail-label'>Comment</span>
                    {getDayCommentElement()}
                </div>
                <div className='jlj-happiness__detail-group'>
                    <span className='jlj-happiness__detail-label'>Score</span>
                    {getDayScoreElement()}
                </div>
            </div>
            <div className='react-yearly-calendar'>
                <CalendarControls year={displayedYear}
                                  showTodayButton
                                  onPrevYear={handlePrevYearClicked}
                                  onNextYear={handleNextYearClicked}
                                  goToToday={handleTodayClicked}/>
                <Calendar
                    key={userData.length}
                    year={displayedYear || moment.utc().year()}
                    selectedDay={moment(displayedDate, DateFormat.LEGACY_MOMENT_SHORT_ISO)}
                    firstDayOfWeek={1}
                    onPickDate={handleDatePicked}
                    customClasses={getDayClass}
                />
            </div>
        </div>
    );
}

// This is used to disregard current timezone and always treat the calendar as if it's in UTC
const normalizeDate = (date: moment.Moment): moment.Moment => {
    const offset = date.utcOffset();
    const date2 = date.utc();

    date2.add(offset, 'minutes');
    return date2;
};

const isDateAfterToday = (date: moment.Moment): boolean => {
    const now = moment().utc().startOf('day');
    return date.isAfter(now);
};
