import * as React from 'react';
import './progressBar.less';

export interface IProgressBarProps {
    percent: number;
    size?: number;
    strokeWidth?: number;
}

// tutorial: https://codepen.io/xgad/post/svg-radial-progress-meters
export class ProgressBar extends React.Component<IProgressBarProps> {
    private circleRef;
    private valueRef;

    static defaultProps = {
        size: 180,
        strokeWidth: 12,
    };

    constructor(props) {
        super(props);
        this.circleRef = React.createRef();
        this.valueRef = React.createRef();
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: IProgressBarProps) {
        this.update(prevProps.percent || 0);
    }

    private update(previousPercent: number = 0) {
        const { size, strokeWidth } = this.props;

        const radius = (size / 2) - (strokeWidth / 2);
        const circumference = 2 * Math.PI * radius;
        const dasharray = 2 * Math.PI * radius;
        const dashoffset = circumference * ((100 - this.props.percent) / 100);

        const previousOffset = circumference * ((100 - previousPercent) / 100); // aka starting offset

        this.circleRef.current.style.strokeDasharray = dasharray;
        this.animateDashoffset(dashoffset, previousPercent, previousOffset);
    }

    private animateDashoffset(dashoffset, previousPercent, previousOffset) {
        let time = 0;
        const duration = 2.5; // Xs animation duration
        const to = dashoffset - previousOffset;

        const animate = () => {
            time += 1/60; // requestAnimationFrame animates at around 60fps
            const percentAnimated = time * 100 / duration;
            const offset = easeInOutQuad(percentAnimated, time, previousOffset, to, duration);
            const counter = easeInOutQuad(percentAnimated, time, previousPercent, (this.props.percent - previousPercent), duration) + '';

            if (this.circleRef.current && this.valueRef.current) {
                // the refs can be destroyed before animation duration is complete
                this.circleRef.current.style.strokeDashoffset = offset;
                this.valueRef.current.textContent = `${counter.split('.')[0]}%`;

                if (time + 1/60 < duration) {
                    requestAnimationFrame(() => {
                        animate();
                    });
                }
            }
        };
        animate();
    }

    render() {
        const radius = (this.props.size / 2) - (this.props.strokeWidth / 2);
        return (
            <div className='progressBarContainer'>
                <svg className='progress' width={this.props.size} height={this.props.size} viewBox={`0 0 ${this.props.size} ${this.props.size}`}>
                    <circle className='progress__track'
                            cx={this.props.size / 2}
                            cy={this.props.size / 2}
                            r={radius} fill='none'
                            stroke='#efefef'
                            strokeWidth={this.props.strokeWidth} />
                    <circle className='progress__fill'
                            ref={this.circleRef}
                            cx={this.props.size / 2}
                            cy={this.props.size / 2}
                            r={radius}
                            fill='none'
                            stroke='rgb(112,213,191)'
                            strokeWidth={this.props.strokeWidth}/>
                    <text ref={this.valueRef} className='progress__value' x='50%' y='55%'>{this.props.percent}%</text>
                </svg>
            </div>
        );
    }
}

//https://stackoverflow.com/questions/8316882/what-is-an-easing-function
// x: percent
// t: current time,
// b: beginning value,
// c: change in value,
// d: duration
function easeInOutQuad(x, t, b, c, d) {
    if ((t /= d / 2) < 1) {
        return c / 2 * t * t + b;
    } else {
        return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }
}
