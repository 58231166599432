import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Raven from 'raven-js';
import isProduction from '../common/utils/isProduction';
import ConnectedApp from './views/app';
import 'bootstrap/dist/js/bootstrap.min';
import '../../assets/bootstrap-lux.less';
import { BrowserRouter } from 'react-router-dom';
import LoggerFactory from '../common/utils/loggerFactory';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { applyInitialState } from './store/login';
import {BOOTSTRAP} from './store/metadata';
import { ThemeProvider } from './ThemeProvider';

const logger = LoggerFactory.createLogger('bootstrapper.tsx');

export default class Bootstrapper {
	// Firefox only allows requiring permission as a result of handling user event
	public static bootstrapNotification = async () => {

		const requestNotificationPermission = async () => {
			const permission = await window.Notification.requestPermission();
			if (permission !== 'granted'){
				console.log('Notifications permission NOT granted');
			} else {
				console.log('Notifications permission granted');
			}
			return permission;
		};

		await requestNotificationPermission();
	};

	private static _applyEnvironmentSpecificConfiguration(): void {
		if (isProduction) {
			Raven.config('https://f1277a93e17249058c9c9e736e20f567@sentry.io/165384').install();

		} else {
			logger.info('Starting app in DEV mode');
		}
	}

	public static start(): void {
		const registerServiceWorker = async () => {
			const swRegistration = await navigator.serviceWorker.register('sw.js'); //notice the file name
			return swRegistration;
		};

		this._applyEnvironmentSpecificConfiguration();
		const registration = registerServiceWorker();
		registration.then(x => {
			console.log('!!!! registration', x);
		});

		// Grab the state from a global variable injected into the server-generated HTML
		const preloadedState = window[ '__PRELOADED_STATE__' ];
		delete window[ '__PRELOADED_STATE__' ];

		if (preloadedState && preloadedState.user) {
			store.dispatch(applyInitialState({
				username: preloadedState.user?.username,
				password: '',
				isLoggedIn: true,
			}));
		}
		store.dispatch(BOOTSTRAP());
		render();
	}
}

function getMountPoint(): Element {
	const mountpoint = document.createElement('div');
	mountpoint.id = 'root';
	document.body.appendChild(mountpoint);
	return mountpoint;
}

function render(): void {
	const root = (
		<Provider store={store}>
			<ThemeProvider>
				<BrowserRouter>
					<ConnectedApp/>
				</BrowserRouter>
			</ThemeProvider>
		</Provider>
	);
	const mountpoint = getMountPoint();
	ReactDOM.unmountComponentAtNode(mountpoint);
	ReactDOM.render(root, mountpoint, () => logger.info('Rendered root app'));
}

Bootstrapper.start();
