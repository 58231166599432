import {Typography} from '@mui/material';
import * as React from 'react';
import {NavLink} from 'react-router-dom';

export function Logo(props: { color: string; }) {
    const component = 'span';
    const style = { color: props.color, my: 2, fontFamily: 'Lobster' };
    return (
        <NavLink exact to='/' className='linkNoStyles'>
            <Typography variant='h4' component={component} sx={style}>A</Typography>
            <Typography variant='h6' component={component} sx={style}>&</Typography>
            <Typography variant='h4' component={component} sx={style}>P</Typography>
        </NavLink>
    );
}
