import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IHappinessRating from '../../common/models/IHappinessRating';

export interface CelendarState {
    entries: Array<IHappinessRating>;
    isBusy: boolean;
    dayComment?: string;
    dayScore?: number;
    displayedYear?: number;
    displayedDate?: string;
}

const initialState: CelendarState = {
    entries: [],
    isBusy: false,
};

export const calendarSlice = createSlice({
    name: 'calendar',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setDayComment: (state, action: PayloadAction<string>) => {
            state.dayComment = action.payload;
        },
        setDayScore: (state, action: PayloadAction<number>) => {
            state.dayScore = action.payload;
        },
        setCalendarEntries: (state, action: PayloadAction<Array<IHappinessRating>>) => {
            state.entries = action.payload;
        },
        setDisplayedDate: (state, action: PayloadAction<string>) => {
            state.displayedDate = action.payload;
        },
        setDisplayedYear: (state, action: PayloadAction<number>) => {
            state.displayedYear = action.payload;
        },
        addCalendarEntryRequest: (state) => {
            state.isBusy = true;
        },
        addCalendarEntryResponse: (state) => {
            state.isBusy = false;
            state.dayComment = '';
            state.dayScore = null;
        },
        deleteCalendarEntryRequest: (state, action: PayloadAction<IHappinessRating>) => {
            state.isBusy = true;
            state.dayComment = action.payload.comment;
            state.dayScore = action.payload.score;
        },
        deleteCalendarEntryResponse: (state) => {
            state.isBusy = false;
        },
    },
});

export const { setDayComment, setDayScore, setCalendarEntries, addCalendarEntryRequest, addCalendarEntryResponse,
    setDisplayedDate, setDisplayedYear, deleteCalendarEntryRequest, deleteCalendarEntryResponse } = calendarSlice.actions;

export const calendarReducer = calendarSlice.reducer;