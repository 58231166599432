import * as React from 'react';
import {ThemeProvider as MuiThemeProvider, createTheme, responsiveFontSizes} from '@mui/material/styles';
import {useContext} from 'react';
import {grey, pink, purple} from '@mui/material/colors';

export type ThemeType = 'light' | 'dark';
export const LIGHT_CONTRAST_TEXT = grey[700];
export const DARK_CONTRAST_TEXT = grey[100];

type ThemeState = {
    theme: ThemeType,
    setTheme(theme: ThemeType): void,
};

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: purple[600],
            light: purple[500],
            dark: purple[700],
            contrastText: DARK_CONTRAST_TEXT
        },
    },
    typography: {
        'fontFamily': `"Montserrat", "Helvetica", "Arial", sans-serif`
    }
});

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: pink[50],
            light: pink[50],
            dark: pink[100],
            contrastText: LIGHT_CONTRAST_TEXT
        },
    },
    typography: {
        'fontFamily': `"Montserrat", "Helvetica", "Arial", sans-serif`
    }
});

const StateContext = React.createContext<ThemeState>({
    theme: 'light',
    setTheme: () => {}
});

export const ThemeProvider = (props: { children: React.ReactNode }) => {
    const [theme, setTheme] = React.useState((localStorage.getItem('theme') === 'dark' ? 'dark' : 'light') as ThemeType);

    return (
        <StateContext.Provider value={{
            theme,
            setTheme
        }}>
            <MuiThemeProvider theme={theme === 'light' ? responsiveFontSizes(lightTheme) : responsiveFontSizes(darkTheme)}>
                {props.children}
            </MuiThemeProvider>
        </StateContext.Provider>
    );
};

export const useThemeState = () => useContext(StateContext);
