import * as React from 'react';
import './calendar/calendar.less';
import './calendar.less';
import Flipper from '../components/flipper/flipper';
import {HappinessCalendar} from './calendar/happinessCalendar';
import {Dashboard} from './dashboard/dashboard';
import {END_STREAM, START_STREAM} from '../../store/metadata';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useDataStream} from '../hooks/useDataStream';

export function Calendar() {
    const data = useSelector((state: RootState) => state.calendar.entries);
    const username = useSelector((state: RootState) => state.login.username);
    useDataStream(MessagingTopics.RegisterHappinessStream);
    if (!data.length) {
        return null;
    }

    const frontContent = (
        <div className='jlj-happiness'>
            <HappinessCalendar username={username}/>
            <div className='jlj-happiness__other-person'>
                <HappinessCalendar username={username === 'ana' ? 'pedro' : 'ana'}/>
            </div>
        </div>
    );

    const backContent = <Dashboard />;

    return <Flipper frontContent={frontContent} backContent={backContent}/>;
}
