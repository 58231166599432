import * as React from 'react';

export interface ITextAreaInputProps {
    placeholder?: string;
    value?: string;
    onChange: (value: string) => void;
    className?: string;
}

export default class TextAreaInput extends React.PureComponent<ITextAreaInputProps> {
    private ref: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidUpdate(): void {
        if (this.ref && !this.props.value) {
            this.ref.current.value = '';
        }
    }
    private handleChange = ev => {
        this.props.onChange(ev.target.value);
    };

    public render(): JSX.Element {
        return (
            <textarea
                ref={this.ref}
                className={this.props.className}
                placeholder={this.props.placeholder}
                defaultValue={this.props.value}
                onChange={this.handleChange}
            />
        );
    }
}