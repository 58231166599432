import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from './store';

export interface LoginState {
    username: string;
    password: string;
    isLoggedIn: boolean;
    hasError?: boolean;
}

const initialState: LoginState = {
    username: '',
    password: '',
    isLoggedIn: false,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        requestLogin: (state, action: PayloadAction<string>) => {
            state.hasError = false;
        },
        handleLoginResponse: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
            state.hasError = !action.payload;
            state.password = '';
        },
        handleLogout: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = false;
        },
        applyInitialState: (state, action: PayloadAction<LoginState>) => {
            return action.payload;
        },
    }
});

export const selectCurrentUser = (state: RootState) => state.login.username;
export const selectOtherUser = (state: RootState) => state.login.username === 'ana' ? 'pedro' : 'ana';

export const { setUsername, setPassword, requestLogin, handleLoginResponse, handleLogout, applyInitialState } = loginSlice.actions;

export const loginReducer = loginSlice.reducer;