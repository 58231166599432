import * as React from 'react';
import cn from 'classnames';
import './button.less';

export enum ButtonSize {
    SMALL,
    NORMAL,
    LARGE,
}

export interface IButtonProps {
    label: string;
    onClick: () => void;
    type?: ButtonType;
    size?: ButtonSize;
    className?: string;
    disabled?: boolean;
}

export enum ButtonType {
    PRIMARY,
    SECONDARY,
    INFO,
    SUCCESS,
    WARNING,
    ERROR,
}

export default class Button extends React.Component<IButtonProps> {

    public render(): JSX.Element {
        const {label, type, onClick, size, className, disabled} = this.props;

        const classNames = cn('btn', {
            'btn-primary': type === ButtonType.PRIMARY,
            'btn-secondary': type === ButtonType.SECONDARY,
            'btn-info': type === ButtonType.INFO || type === undefined,
            'btn-success': type === ButtonType.SUCCESS,
            'btn-warning': type === ButtonType.WARNING,
            'btn-error': type === ButtonType.ERROR,
            'custom-btn-small': size === ButtonSize.SMALL,
            'btn-sm': size === ButtonSize.NORMAL,
            'btn-lg': size === ButtonSize.LARGE,
        }, className);

        return (
            <button onClick={onClick} className={classNames} disabled={disabled}>
                {label}
            </button>
        );
    }
}