import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuote } from '../../common/models';

export interface QuotesState {
    quote: IQuote;
}

const initialState: QuotesState = {
    quote: null,
};

export const ping = createAction<number>('PING');
export const pong = createAction<number>('PONG');

export const quotesSlice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        setQuote: (state, action: PayloadAction<IQuote>) => {
            state.quote = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ping, (state, action) => {
                return initialState;
            })
            .addCase(pong, (state, action) => {
                return initialState;
            });
      },
});

export const { setQuote } = quotesSlice.actions;

export const quotesReducer = quotesSlice.reducer;