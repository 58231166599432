import { v4 as uuidv4 } from 'uuid';

export default class Envelope<T = {}> {
	private _correlationId: string;
	private _topic: string;
	private _payload: T;

	constructor(correlationId: string, topic: string, payload: T) {
		this._correlationId = correlationId;
		this._topic = topic;
		this._payload = payload;
	}

	public static create<T>(topic: string, payload: T, correlationId: string = uuidv4()): Envelope<T> {
		return new Envelope<T>(correlationId, topic, payload);
	}

	public static deserialize<T>(message: string): Envelope<T> {
		const obj: any = JSON.parse(message);
		return new Envelope<T>(obj._correlationId, obj._topic, obj._payload);
	}

	public serialize(): string {
		return JSON.stringify(this);
	}

	public get correlationId(): string {
		return this._correlationId;
	}

	public get topic(): string {
		return this._topic;
	}

	public get payload(): T {
		return this._payload;
	}
}
