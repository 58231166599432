import * as React from 'react';
import classnames from 'classnames';
import {IGoalItem, IGoalOrdering} from '../../../../common/models/IGoalItem';
import {DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {isMobileOrTablet} from '../../../../common/utils/isMobileOrTablet';
import Button, {ButtonType} from '../../components/buttons/button';
import {Map} from 'immutable';
import {ProgressBar} from '../../components/progress-bar/progressBar';
import {GoalContainer} from './goalContainer';
import {GoalComponent} from './goalComponent';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {addGoal, deleteGoal, saveGoalsRequest} from '../../../store/goals';
import { v4 as uuidv4 } from 'uuid';
import {DateTime} from 'luxon';
import {DateFormat} from '../../../../common/utils/dateUtils';
import {useMemo} from 'react';
import {createSelector, createSelectorCreator, defaultMemoize} from 'reselect';
import * as isEqual from 'lodash.isequal';

const backendToUse = isMobileOrTablet() ? TouchBackend : HTML5Backend;
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, {
    equalityCheck: isEqual,
    resultEqualityCheck: isEqual
} as any);

const selectUserGoals = createSelector(
    (state: RootState) => state.goals.goals,
    (_, username: string) => username,
    (goals, username) => goals.filter(goal => goal.username === username),
);

const makeSelectGoals = () => createDeepEqualSelector(
    selectUserGoals,
    goals => goals.reduce((acc, current) => acc.set(current.id, current), Map<string, IGoalItem>())
);

const makeSelectOrdering = () => createDeepEqualSelector(
    (state: RootState) => state.goals.ordering,
    (_, username) => username,
    (ordering, username) => ordering.find(ordering => ordering.username === username)
);

const dirtySelector = createSelector(
    (state: RootState) => state.goals.pendingChanges,
    pendingChanges => pendingChanges.hasOrderingChange ||
        Object.keys(pendingChanges.added).length !== 0 ||
        Object.keys(pendingChanges.updated).length !== 0 ||
        Object.keys(pendingChanges.removed).length !== 0
);

export const GoalSection = React.memo((props: any) => {
    const selectUserGoals = useMemo(makeSelectGoals, []);
    const selectOrdering = useMemo(makeSelectOrdering, []);

    const username = props.username;

    const goals = useSelector((state: any) => selectUserGoals(state, username));

    const loggedInUser = useSelector((state: RootState) => state.login.username);

    const ordering = useSelector((state: any) => selectOrdering(state, username));
    const isDirty = useSelector(dirtySelector);

    const belongsToUser = props.username === loggedInUser;
    const isBusy = useSelector((state: RootState) => state.goals.isBusy);

    const dispatch = useDispatch();
    const handleAddNew = () => dispatch(addGoal({
        username, id: uuidv4(), dateAdded: DateTime.now().toFormat(DateFormat.SHORT_ISO)
    }));
    const handleSave = () => dispatch(saveGoalsRequest(username));

    const topLevelGoals = goals
        .filter((value, key) => ordering.order.find(o => o.id === key))
        .valueSeq().toArray()
        .sort((a, b) => {
            const aOrder = ordering.order.find(o => o.id === a.id).position;
            const bOrder = ordering.order.find(o => o.id === b.id).position;
            return aOrder - bOrder;
        });

    const items = topLevelGoals.map((goal, index) => (
        <GoalContainer key={goal.id} index={index} goal={goal} belongsToUser={belongsToUser} ordering={ordering}>
            <GoalComponent index={index} goal={goal} goals={goals} belongsToUser={belongsToUser} ordering={ordering}/>
        </GoalContainer>
    ));

    const className = classnames('section-container', {
        'section-container--other': !belongsToUser,
    });
    const numberOfCompleted = goals.filter(g => !!g.dateCompleted).size;

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <div className={className}>
            {belongsToUser ?
                <>
                    <Button label={'New Goal'} onClick={handleAddNew} type={ButtonType.SUCCESS}
                            className={'new-goal-button'} disabled={false}/>
                    <Button label={'Save'} onClick={handleSave} type={ButtonType.INFO}
                            className={'new-goal-button'} disabled={isBusy || !isDirty}/>
                </> : null}
            <div className='section-autosizer'>
                <div className={classnames('section-container__panel')}>
                    <div className='section-container__summary'>
                        <ProgressBar percent={Math.floor(numberOfCompleted * 100 / goals.size ) || 0}/>
                        <div className='section-container__summary-stats'>
                            <div className='section-container__summary-stats-total'>Total: {goals.size}</div>
                            <div>Completed: {numberOfCompleted}</div>
                        </div>
                    </div>
                    <div className='section-container__items'>
                        {/*@ts-ignore*/}
                        <DndProvider backend={backendToUse}>
                            {items}
                        </DndProvider>
                    </div>
                </div>
            </div>
        </div>
    );
});
