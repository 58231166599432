import * as React from 'react';
import './homepage.less';
import {IQuote} from '../../../common/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {END_STREAM, START_STREAM} from '../../store/metadata';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import {useThemeState} from '../../ThemeProvider';
import {pink, purple} from '@mui/material/colors';

export interface IHomepageProps {
	startStreaming: (_: {}) => void;
	cancelStreaming: (_: {}) => void;
	quote: IQuote;
}

export function Homepage() {
	const quote = useSelector((state: RootState) => state.quotes.quote);
	const { theme } = useThemeState();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(START_STREAM(MessagingTopics.RegisterQuotesStream));
		return () => { dispatch(END_STREAM(MessagingTopics.RegisterQuotesStream)); };
	}, []);

	if (!quote) {
		return <div></div>;
	}
	return (
		<div className='homepage'>
			<div className='quote-container'>
				<div className='quote'>
					{quote.message}
				</div>
				<div className='author'>
					{quote.author}
				</div>
			</div>
			<div className='area'
				 style={{
					 background: `linear-gradient(0deg, ${theme === 'light' ? pink[600] : purple[900]} 0%, ${theme === 'light' ? pink[50] : purple[600]} 100%)`
			}}>
				<ul className='circles'>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
					<li></li>
				</ul>
			</div>
		</div>
	);
}
