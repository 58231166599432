import * as React from 'react';
import {useDrag} from 'react-dnd';
import {IGoalItem} from '../../../../common/models';
import TextInput from '../../components/inputs/textInput';
import Button, {ButtonSize, ButtonType} from '../../components/buttons/button';
import {GoalContainer, IGoalContainerProps} from './goalContainer';
import {useDispatch, useSelector} from 'react-redux';
import {deleteGoal, editGoal, GoalEvent, updateGoal} from '../../../store/goals';
import {DateTime} from 'luxon';
import {DateFormat} from '../../../../common/utils/dateUtils';
import {RootState} from '../../../store/store';

export const GoalComponent: React.FunctionComponent<any> = props => {
    const {goal, index, belongsToUser, ordering, goals} = props;
    const editId = useSelector((state: RootState) => state.goals.editId);
    const dispatch = useDispatch();
    const updateGoalItem = (goal: GoalEvent) => dispatch(updateGoal(goal));
    const handleEditRequested = (id: string) => dispatch(editGoal({ id, username: goal.username }));
    const handleDeleteRequested = () => dispatch(deleteGoal({ id: goal.id, username: goal.username }));

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'Goal',
        item: { goal },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: () => belongsToUser && !editId
    }));

    const subGoalIds = ordering.groups.find(g => g.id === goal.id);

    let subTasksContainer = null;
    if (subGoalIds) {
        const goalItems = subGoalIds.children.map(id => goals.get(id));

        const subTasks = goalItems.map((value: IGoalItem, childIndex: number) => (
            <GoalContainer key={value.id} {...props} isChild={true} index={childIndex} goal={value}>
                <GoalComponent {...props} isChild={true} index={childIndex} goal={value}/>
            </GoalContainer>
        ));
        subTasksContainer = (
            <div className='subtask-container'>
                {subTasks}
            </div>
        );
    }

    const handleTextChange = text => {
        updateGoalItem({...goal, goal: text});
    };

    const handleCheckboxChange = () => {
        updateGoalItem({...goal, dateCompleted: !!goal.dateCompleted ? undefined : DateTime.now().toFormat(DateFormat.SHORT_ISO)});
    };

    const editingView =  <TextInput value={goal.goal} onChange={handleTextChange} onBlur={() => handleEditRequested(undefined)} autoFocus={true}/>;
    const normalView = (
        <div className='goal-item-content'>
            <span>{goal.goal}</span>
            <input type='checkbox'
                   disabled={!belongsToUser}
                   checked={!!goal.dateCompleted}
                   onChange={handleCheckboxChange}/>
            {
                belongsToUser ? (
                    <div className='goal-item-control'>
                        <Button label={'Edit'} onClick={() => handleEditRequested(goal.id)} type={ButtonType.INFO} size={ButtonSize.SMALL}/>
                        <Button label={'Delete'} onClick={handleDeleteRequested} type={ButtonType.WARNING} size={ButtonSize.SMALL}/>
                    </div>
                ) : null
            }
        </div>
    );

    const isEditing = editId === goal.id;

    return (
        <div ref={drag} className='goal-item'>
            {isEditing ? editingView : normalView}
            {subTasksContainer}
        </div>
    );
};