import * as React from 'react';
import './flipper.less';

export interface IFlipperProps {
    frontContent: JSX.Element;
    backContent: JSX.Element;
    isFlipped?: boolean;
}

export default class Flipper extends React.Component<IFlipperProps> {
    private ref: HTMLDivElement;

    public render(): JSX.Element {
        return (
            <div className='flipper-container'>
                <div className='flipper-header'>
                    <button onClick={this.handleFlip}><i className='material-icons'>cached</i></button>
                </div>
                <div ref={r => this.ref = r!} className={'flipper'}>
                    <div className='flipper__front'>
                        {this.props.frontContent}
                    </div>
                    <div className='flipper__back'>
                        {this.props.backContent}
                    </div>
                </div>
            </div>
        );
    }

    private handleFlip = () => {
        if (this.ref) {
            this.ref.classList.toggle('flipper--flipped');
        }
    }
}