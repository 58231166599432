import * as React from 'react';
import {useDrop} from 'react-dnd';
import {IGoalItem, IGoalOrdering} from '../../../../common/models';
import classnames from 'classnames';
import {useDispatch} from 'react-redux';
import { GoalOrderingChange, updateGoalOrdering } from '../../../store/goals';

export interface IGoalContainerProps {
    goal: IGoalItem;
    index: number;
    isChild?: boolean;
    belongsToUser: boolean;
    ordering: IGoalOrdering;
    children?: JSX.Element;
}

export const GoalContainer: React.FunctionComponent<IGoalContainerProps> = props => {
    const {index, children, goal, belongsToUser, ordering, isChild} = props;

    const dispatch = useDispatch();
    const handleUpdateOrder = (ev: GoalOrderingChange) => dispatch(updateGoalOrdering(ev));

    const [{isOver, acceptNesting}, drop] = useDrop({
        accept: 'Goal',
        drop: (itemBeingMoved: { goal: IGoalItem }, monitor) => {
            if (!isOver || monitor.didDrop()) {
                return;
            }

            const parentGroupId = isChild && ordering.groups.find(g => g.children.find(id => id === goal.id)).id;
            const currentGroup = ordering.groups.find(g => g.id === itemBeingMoved.goal.id);

            const ev = {
                sourceId: itemBeingMoved.goal.id,
                targetId: isChild && !currentGroup ? parentGroupId : undefined,
                newIndex: index,
                username: goal.username
            };

            handleUpdateOrder(ev);
        },
        collect: monitor => {
            const children = ordering.groups.find(group => group.id === goal.id);
            const isSingleton = !isChild && !children;
            const isOver = monitor.isOver({shallow: !isSingleton});
            return {
                isOver,
                acceptNesting: isOver && isSingleton,
            };
        },
        canDrop: () => belongsToUser
    });

    return (
        <div ref={drop} className={classnames('goal-item-container', {'goal-item-container--isOver': isOver})}>
            {acceptNesting ? <GoalNester {...props}/> : null}
            {children}
        </div>
    );
};

const GoalNester: React.FunctionComponent<IGoalContainerProps> = props => {
    const {index, belongsToUser, ordering, goal} = props;
    const dispatch = useDispatch();
    const handleUpdateOrder = (ev: GoalOrderingChange) => dispatch(updateGoalOrdering(ev));

    const [{isOver}, drop] = useDrop({
        accept: 'Goal',
        drop: (itemBeingMoved: { goal: IGoalItem }) => {
            if (!isOver) {
                return;
            }

            const sourceIsGroup = ordering.groups.find(g => g.id === itemBeingMoved.goal.id); // can't move a grouped goal into another goal, causing 2 level deep
            const sameTarget = goal.id === itemBeingMoved.goal.id;

            if (sourceIsGroup || sameTarget) {
                return;
            }

            const ev = {
                sourceId: itemBeingMoved.goal.id,
                targetId: goal.id,
                nest: true,
                username: goal.username
            };
            handleUpdateOrder(ev);
        },
        collect: monitor => {
            const isOver = monitor.isOver({shallow: false});
            return {isOver};
        },
        canDrop: () => belongsToUser
    });

    return (
        <div ref={drop} className={classnames('goal-nester', {'goal-nester--isOver': isOver})}>
            Group
        </div>
    );
};
