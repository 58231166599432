import { filter, map, switchMap } from 'rxjs/operators';
import { Action } from 'redux';
import { requestLogin } from '../login';
import { EpicType } from '../store';
import {BOOTSTRAP, setIsWsAlive} from '../metadata';
import {PayloadAction} from '@reduxjs/toolkit/src/createAction';
import {ofType} from 'redux-observable';

export const wsConnectionEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    ofType(BOOTSTRAP),
    switchMap(() => dependencies.messagingHub.socketValid$.pipe(
        map((socketStatus: boolean) => setIsWsAlive(socketStatus))
    ))
);
