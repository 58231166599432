import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { quotesReducer } from './quotes';
import { timelineReducer } from './timeline';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { loginReducer } from './login';
import { metadataReducer } from './metadata';
import MessagingHub from '../services/messagingHub';
import { loginEpic } from './epics/login';
import {wsConnectionEpic} from './epics/wsConnection';
import {dataStreamingEpic} from './epics/dataStreaming';
import {addTimelineEntryEpic} from './epics/addTimelineEntry';
import {calendarReducer} from './calendar';
import {addCalendarEntryEpic} from './epics/addCalendarEntry';
import {deleteCalendarEntryEpic} from './epics/deleteCalendarEntry';
import {goalsReducer} from './goals';
import {saveGoalsEpic} from './epics/saveGoals';

export interface EpicDependency {
    messagingHub: MessagingHub;
}

const epicMiddleware = createEpicMiddleware<Action, Action, RootState, EpicDependency>({
    dependencies: {
        messagingHub: MessagingHub.getInstance(),
    }
});

export const rootEpic = combineEpics(
    loginEpic,
    wsConnectionEpic,
    dataStreamingEpic,
    addTimelineEntryEpic,
    addCalendarEntryEpic,
    deleteCalendarEntryEpic,
    saveGoalsEpic,
);

const reducers = combineReducers({
    metadata: metadataReducer,
    login: loginReducer,
    quotes: quotesReducer,
    timeline: timelineReducer,
    calendar: calendarReducer,
    goals: goalsReducer,
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(epicMiddleware),
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export type EpicType = Epic<Action, Action, RootState, EpicDependency>;
