import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { Action } from 'redux';
import { requestLogin, handleLoginResponse } from '../login';
import { EpicType } from '../store';

export const loginEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    filter((action: Action<any>) => action.type === requestLogin.type),
    switchMap(() => dependencies.messagingHub.post('login', { username: state$.value.login.username, password: state$.value.login.password }).pipe(
        map((response: any) => handleLoginResponse(response.success))
    )),
);
