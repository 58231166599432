import * as React from 'react';
import './goals.less';
import {GoalSection} from './section/goalsSection';
import {useSelector} from 'react-redux';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import {useDataStream} from '../hooks/useDataStream';
import {selectCurrentUser, selectOtherUser} from '../../store/login';

export function Goals() {
	useDataStream(MessagingTopics.RegisterGoalsStream);

	return (
		<div className='jlj-goals'>
			<GoalSection username={useSelector(selectCurrentUser)}/>
			<GoalSection username={useSelector(selectOtherUser)}/>
		</div>
	);
}
