import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import {EpicType, RootState} from '../store';
import {ofType} from 'redux-observable';
import {addTimelineEntryRequest, addTimelineEntryResponse} from '../timeline';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import Envelope from '../../../common/messaging/envelope';

export const addTimelineEntryEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    ofType(addTimelineEntryRequest),
    map(() => {
        const state: RootState = state$.value;
        const [ year, month, day ] = state.timeline.date.split('-');
        return {
            username: state.login.username,
            date: new Date().toJSON(),
            year: Number(year),
            month: Number(month),
            day: Number(day),
            title: state.timeline.title,
            text: state.timeline.text.replace(/\n/g, '<br />'),
            mediaUrl: state.timeline.mediaUrl,
        };
    }),
    switchMap((request: any) => dependencies.messagingHub.getStream(MessagingTopics.AddTimelineEntry, request).pipe(
        map((response: Envelope<any>) => {
            return addTimelineEntryResponse();
        })
    )),
);
