import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import { EpicType } from '../store';
import {PayloadAction} from '@reduxjs/toolkit/src/createAction';
import Envelope from '../../../common/messaging/envelope';
import {setQuote} from '../quotes';
import {END_STREAM, START_STREAM} from '../metadata';
import {ofType} from 'redux-observable';
import MessagingTopics from '../../../common/messaging/messagingTopics';
import {setTimelineEntries} from '../timeline';
import {setCalendarEntries} from '../calendar';
import {setGoalEntries} from '../goals';

export const dataStreamingEpic: EpicType = (action$, state$, dependencies) => action$.pipe(
    ofType(START_STREAM),
    switchMap((action: PayloadAction<string>) => dependencies.messagingHub.getStream(action.payload).pipe(
        map((response: Envelope<any>) => {
            const topic = response.topic;
            switch (topic) {
                case MessagingTopics.RegisterQuotesStream:
                    return setQuote(response.payload);
                case MessagingTopics.RegisterTimelineStream:
                    return setTimelineEntries(response.payload);
                case MessagingTopics.RegisterHappinessStream:
                    return setCalendarEntries(response.payload);
                case MessagingTopics.RegisterGoalsStream:
                    return setGoalEntries(response.payload);
                default:
                    console.error('Unrecognized topic: ', topic);
            }
        }),
        takeUntil(action$.pipe(ofType(END_STREAM)))
    ))
);
